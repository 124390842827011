/**
 * Created by koko on 20/01/16.
 */
"use strict";

$.ajaxSetup({
    headers: { 'X-CSRF-Token' : $('meta[name=csrf_token]').attr('content') }
});
var wp = null;
(function($) {
    $.extend({
        viewport: function () {
            var viewPortWidth;
            var viewPortHeight;
            if (typeof window.innerWidth != 'undefined') {
                viewPortWidth = window.innerWidth;
                viewPortHeight = window.innerHeight;
            }
            else if (typeof document.documentElement !== 'undefined'
                && typeof document.documentElement.clientWidth !== 'undefined'
                && document.documentElement.clientWidth !== 0) {
                viewPortWidth = document.documentElement.clientWidth;
                viewPortHeight = document.documentElement.clientHeight;
            }
            else {
                viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
                viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
            }
            return {width: viewPortWidth, height: viewPortHeight};
        }
    });
    $.fn.extend({
        loadMore: function (options) {
            options = options || {};
            var settings = $.extend({
                excludeFooter: true,
                buffer: 300
            }, options);
            var loading = false;
            this.each(function () {
                var el = $(this);
                $(window).on('scroll', function () {
                    var buffer = settings.buffer;
                    if(settings.excludeFooter) {
                        buffer += $('footer').outerHeight();
                    }
                    if(($(window).scrollTop() + $(window).height() + buffer) > $(document).height() && !loading) {
                        loading = true;
                        var config = el.data();
                        $('.loader').addClass('on');
                        $.post(BASE_URL + 'ajax/archive/load', config, function (response) {
                            $('.loader').removeClass('on');
                            if(response.count > 0) {
                                for(var i = 0; i < response.count; i++) {
                                    $(response.items[i]).insertAfter($('article', el).last()).fadeIn(600);
                                }
                                el.data('showed', config.showed + response.count);
                                loading = false;
                            }
                        }, 'json');
                    }
                });
            });
        },
        fireWhenVisible: function (callback, options) {
            callback = callback || function (el) { el.addClass('show') };
            var settings = $.extend({
                topOffset: 0
            }, options);

            return this.each(function(){
                var el = this;
                if(!$(el).hasClass('fwv-fired')) {
                    if(!wp) {
                        wp = $.viewport();
                    }
                    var bottom = $(window).scrollTop() + wp.height + settings.topOffset;
                    if(bottom >= el.getBoundingClientRect().top + document.documentElement.scrollTop) {
                        $(el).addClass('fwv-fired');
                        callback($(el));
                    }
                }
                $(window).scroll(function() {
                    if(!$(el).hasClass('fwv-fired')) {
                        var bottom = $(window).scrollTop() + wp.height + settings.topOffset;
                        if(bottom >= el.getBoundingClientRect().top + document.documentElement.scrollTop) {
                            $(el).addClass('fwv-fired');
                            callback($(el));
                        }
                    }
                });
            });
        }
    });

    $('.block, section.help, .help-blocks, .helpers, section.news').fireWhenVisible(null, {topOffset: 0});
    var thanksBlock = $('#thanks-block');
    if(thanksBlock.length > 0) {
        setInterval(function() {
            var excludes = [];
            $('.thanks-item').each(function () {
                excludes.push($(this).data('id'));
            });
            $.get(BASE_URL + CUR_LANG + '/ajax/get-thanks', {
                excludes: excludes
            }, function(response) {
                if(response.items.length > 0) {
                    var currentItems = $('.thanks-item');
                    $('.logos', thanksBlock).append(response.items);
                    $('.thanks-item.hidden').slideDown(function () {
                        $(this).removeClass('hidden');
                    });
                    currentItems.slideUp(function() {
                        $(this).empty().remove();
                    });
                }
            }, 'json');
        }, 15000);
    }
}(jQuery));

wp = $.viewport();

$(function() {
    wp = $.viewport();
    var w = wp.width;
    var filler = $('.filler');
    if(w > 1200) {
        var fw = Math.round((w - 1200) / 2 + 10);
        filler.css('width', fw + 'px');
    }

    $(window).on('resize', function() {
        wp = $.viewport();
        w = wp.width;
    });

    $(document).on('click', '.burger', function(event) {
        event.preventDefault();
        $(this).toggleClass('open');
        $('nav').toggleClass('open');
    });

    $(document).on('click', '.main-more', function (event) {
        event.preventDefault();
        var el = $(this);
        el.toggleClass('up');
        $('.description', el.parent()).toggleClass('open');
    });

    $(window).on('scroll', function () {
        var sct = $(this).scrollTop();
        if(sct >= 50) {
            $('header').addClass('small');
        } else {
            $('header').removeClass('small');
        }
    });

    $(document).on('click', '.anchor', function (event) {
        var el = $(this);
        var anchored = $('#' + el.data('anchor'));
        if(anchored.length > 0) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: (anchored.position().top - 100) + 'px'
            });
        }
    });

    var hash = window.location.hash;
    if(hash != '') {
        var anchored = $(hash);
        if(anchored.length > 0) {
            setTimeout(function() {
                $('html, body').animate({
                    scrollTop: (anchored.position().top - 100) + 'px'
                });
            }, 200);
        }
    }

    $('section.archive').loadMore();

});